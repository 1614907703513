/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

import {
  _getDocumentLocale,
  _getProfileFieldAtKeyPath,
  betterTime,
} from './formatters-internal.js';

export function littleDate(profile, keyPath = 'time.start') {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return null;
  }

  const date = betterTime(dateString);
  const locale = _getDocumentLocale();
  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return {
    day: date.getDate(),
    month: date.toLocaleString(locale, { month: 'short' }),
    time: time,
  };
}

export function timeRange(
  profile,
  key = 'time',
  dateFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  separator = '-') {

  const dateField = profile[key];
  if (!dateField) {
    console.error('could not find ', key, profile);
    return null;
  }

  if (!(dateField.start || dateField.end)) {
    console.error(key, 'is empty', profile);
    return null;
  }

  const locale = _getDocumentLocale();
  const start = betterTime(dateField.start);
  const end = betterTime(dateField.end);
  const startString = start.toLocaleString(locale, dateFormatOptions);
  let endString = end.toLocaleString(locale, dateFormatOptions);

  if (startString && endString) {
    if (start.toLocaleDateString() === end.toLocaleDateString()) {
      endString = end.toLocaleString(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }

    return `${startString} ${separator} ${endString}`;
  }

  return endString;
}
